
import { Component, Watch } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Action, Getter, Mutation } from "vuex-class";
import { IPriceTagListRequest, IPriceTag } from "@/types/price_tag";
import { IPagination } from "@/types";

@Component({})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  // price_tag
  @Action("price_tag/adminGetList")
  public getList!: (request: IPriceTagListRequest) => boolean;

  @Getter("price_tag/list")
  public priceTagList!: IPriceTag[];

  @Getter("price_tag/pagination")
  public pagination!: IPagination<IPriceTag[]>;

  @Mutation("price_tag/clear")
  public clear!: () => void;

  // watch
  @Watch("$route.query")
  public async changeQuery() {
    this.queryToParams();
    await this.getList(this.params);
  }

  // パラメータ定義
  public params: IPriceTagListRequest = {
    page: 1
  };

  //
  // variables
  //
  public headers = [
    { text: "諸経費名", value: "name", sortable: false },
    { text: "値引", value: "is_minus", sortable: false }
  ];

  //
  // methods
  //
  private async created() {
    this.clear();
    this.queryToParams();
    await this.getList(this.params);
  }

  // urlからパラメータをセットする
  private queryToParams() {
    const query = this.$route.query;
    this.params = {
      page: query.page ? Number(query.page) : 1
    };
  }

  // 1ページ目から検索
  // 主に検索条件が変更されたときに利用
  public async searchFirst() {
    this.params.page = 1;
    await this.paramsToQuery();
  }

  // URLのパラメータをセットする
  private paramsToQuery() {
    this.$router
      .push({
        path: this.$router.currentRoute.path,
        query: {
          page: this.params.page ? String(this.params.page) : "1"
        }
      })
      .catch(() => {});
  }

  // 行をクリックした際の挙動
  public rowClick(price_tag: IPriceTag) {
    this.$router.push(`/admin/price_tag/${price_tag.id}`);
  }
}
